import ContactsPage from 'Views/contacts/ContactsPage'
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ContactsPageQuery } from 'Typings/generated-gatsby-types'

export default function Contacts(props: PageProps<ContactsPageQuery>) {
  return <ContactsPage data={props.data?.strapiContactsPage} path={props.path} />
}

export const query = graphql`
  query($language: String!) {
    strapiContactsPage(locale: { eq: $language }) {
      seo {
        meta_title
        meta_description
        meta_image {
          localFile {
            publicURL
          }
        }
      }
      heading
      sub_heading
      form_label
      form_text_placeholder
      texts {
        label
        texts {
          id
          title
          description
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
