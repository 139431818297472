import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AltPageLayout from 'Components/layout/AltPageLayout'
import BasicSection from 'Components/sections/BasicSection'
import BasicSectionTexts from 'Components/sections/BasicSectionTexts'
import Row from 'Components/Row/Row'
import Input, { INPUT_TYPES, InputCallbackData } from 'Components/inputs/Input/Input'
import { fadeUp } from 'Utils/animations'
import Touchable from 'Components/touchables/Touchable'
import Text from 'Components/texts/Text'
import Button from 'Components/touchables/Button'
import Menu from 'Components/layout/Menu'
import { useMediaMatches } from 'Utils/mq'
import { requestCreateOrder, RequestCreateOrderResponse } from 'Utils/network/requests'
import { HttpResponseSuccess } from 'Utils/network/HttpClient'
import { Snackbars } from 'Config/snackbars'
import HttpResponseError from 'Utils/network/HttpResponseError'
import styles from './ContactsPage.module.scss'
import type { OrderFormType, OrderType } from 'Components/layout/Footer'
import type { PageDataType } from 'Typings/api'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'

const ContactsPage = (props: PageDataType) => {
  const { path, data } = props

  const [isFetchingForm, setIsFetchingForm] = useState(false)
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [text, setText] = useState('')

  const isSmallDevice = useMediaMatches({ maxWidth: 600 /* Как в CSS */ })

  const { t } = useTranslation()

  useMaybeChangeLanguageOnInit()

  const order = t('order', { returnObjects: true }) as OrderType
  const { form = {} as OrderFormType } = order || {}

  if (!data) {
    return null
  }

  const formSubmit = () => {
    setIsFetchingForm(true)
    requestCreateOrder({ name: name.trim(), contact: contact.trim(), text: text.trim() })
      .then((r: HttpResponseSuccess<RequestCreateOrderResponse>) => {
        setIsFetchingForm(false)
        setName('')
        setContact('')
        setText('')
        Snackbars.enqueue(order.success, { variant: 'success' })
        return r
      })
      .catch((e: HttpResponseError) => {
        const error = e?.message || order.error
        Snackbars.enqueue(error, { variant: 'error' })
        setIsFetchingForm(false)
      })
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={data.seo}
        path={path}
        title={data.heading}
        subTitle={data.sub_heading}
        footerProps={{ type: 'simple' }}
        firstSectionHasLabel={Boolean(data.form_label)}
      >
        <BasicSection
          index={0}
          label={data.form_label}
          labelVariant="md"
          className={styles.formSection}
          labelTextClassName={styles.formLabelText}
        >
          <div className={styles.form}>
            <Row className={styles.formFirstRow}>
              <Input
                className={styles.formInput}
                label={form.name}
                value={name}
                onChange={(_data: InputCallbackData) => setName(_data.value)}
                {...fadeUp({ index: 1 })}
              />
              <Input
                className={styles.formInput}
                label={form.contact}
                value={contact}
                onChange={(_data: InputCallbackData) => setContact(_data.value)}
                {...fadeUp({ index: 2 })}
              />
            </Row>
            <Input
              className={classNames([styles.formInput, styles.formTextArea])}
              type={INPUT_TYPES.textArea}
              label={data.form_text_placeholder}
              value={text}
              onChange={(_data: InputCallbackData) => setText(_data.value)}
              rows={isSmallDevice ? 12 : 6}
              {...fadeUp({ index: 3 })}
            />
          </div>
          <Row className={styles.formFooter}>
            <div className={styles.formFooterEmail}>
              <Touchable href={`mailto:${t('email')}`}>
                <Text variant="sm" {...fadeUp({ index: isSmallDevice ? 3 : 4 })}>
                  {t('email')}
                </Text>
              </Touchable>
              <Text variant="xs" {...fadeUp({ index: isSmallDevice ? 2 : 5 })}>
                {t('emailLabel')}
              </Text>
            </div>
            <div
              className={styles.formFooterButtonContainer}
              {...fadeUp({ index: isSmallDevice ? 1 : 6 })}
            >
              <Button
                className={styles.formFooterButton}
                isFetching={isFetchingForm}
                title={form.submit}
                disabled={!name.trim() || !contact.trim()}
                onClick={formSubmit}
              />
            </div>
          </Row>
        </BasicSection>
        <BasicSectionTexts
          data={{
            ...data.texts,
            type: 'texts',
            id: 1,
            // @ts-ignore: How to fix this?
            styling: { background: 'Light' },
          }}
          index={1}
          subtractIndentsTop
        />
      </AltPageLayout>
    </>
  )
}

export default ContactsPage
