import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import { withNewLines } from 'Utils/api'
import BaseContainer, {
  PageContainerVerticalIndents,
} from 'Components/BaseContainer/BaseContainer'
import Text, { TextVariants } from 'Components/texts/Text'
import { fadeUp } from 'Utils/animations'
import { DynamicSectionsDataType } from 'Components/sections/DynamicSections'
import styles from './BasicSection.module.scss'

export type BasicSectionStyling = {
  background: string // BasicSectionBackgrounds
}

export const BasicSectionBackgrounds = {
  light: 'Light',
  grey: 'Grey',
}

export const isGreyStyling = (styling?: BasicSectionStyling) => {
  return styling && get(styling, 'background') === BasicSectionBackgrounds.grey
}

export const isGreySection = (section?: Partial<DynamicSectionsDataType>) => {
  return section && isGreyStyling(get(section, ['styling']))
}

export type BasicSectionProps = {
  className?: string
  contentClassName?: string
  labelTextClassName?: string
  index?: number
  label: string
  styling?: BasicSectionStyling
  labelVariant?: TextVariants
  renderAfterLabel?: ReactNode
  subtractIndentsTop?: boolean
  children: ReactNode
  vertical?: PageContainerVerticalIndents
}

const BasicSection = (props: BasicSectionProps) => {
  const {
    className = '',
    contentClassName = '',
    labelTextClassName = '',
    index,
    label,
    labelVariant = 'sm-bold',
    renderAfterLabel = null,
    subtractIndentsTop,
    children,
    vertical = 'sm',
  } = props

  return (
    <BaseContainer
      className={classNames([
        styles.root,
        className,
        {
          [styles.noLabel]: !label,
          [styles.noIndentsTop]: index === 0, // У первого блока всегда нет отступа сверху
          [styles.subtractIndentsTop]: index !== 0 && subtractIndentsTop,
          [styles.isGrey]: isGreyStyling(props.styling),
        },
      ])}
      contentClassName={styles.content}
      vertical={vertical}
      Component="section"
    >
      <div className={styles.label}>
        {label && (
          <Text
            className={classNames([styles.labelText, labelTextClassName])}
            variant={labelVariant}
            {...fadeUp({ index: 0 })}
          >
            {withNewLines(label)}
          </Text>
        )}
        {renderAfterLabel}
      </div>
      <div className={classNames([styles.children, contentClassName])}>{children}</div>
    </BaseContainer>
  )
}

export default BasicSection
