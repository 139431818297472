import React from 'react'
import { size } from 'lodash'
import classNames from 'classnames'
import { DynamicSections_ComponentSectionsSectionTexts_Fragment } from 'Typings/generated-gatsby-types'
import BasicSection from 'Components/sections/BasicSection'
import Text from 'Components/texts/Text'
import { DynamicSectionProps } from 'Components/sections/DynamicSections'
import { ANIM_DELAY, fadeUp } from 'Utils/animations'
import styles from './BasicSectionTexts.module.scss'

export type BasicSectionTextsProps = {
  className?: string
} & DynamicSectionProps<DynamicSections_ComponentSectionsSectionTexts_Fragment>

const COUNT_FOR_COLUMN = 4

const BasicSectionTexts = (props: BasicSectionTextsProps) => {
  const { className = '', data, index, subtractIndentsTop } = props

  if (!size(data.texts)) {
    return null
  }

  return (
    <BasicSection
      className={classNames([styles.root, className])}
      index={index}
      label={data.label}
      styling={data.styling}
      subtractIndentsTop={subtractIndentsTop}
    >
      <div
        className={classNames([
          styles.texts,
          data.texts.length >= COUNT_FOR_COLUMN ? styles.column : styles.row,
        ])}
      >
        {data.texts.map((text, _index) => (
          <div className={styles.item} key={text.id}>
            {text.title && (
              <Text
                className={styles.itemTitle}
                {...fadeUp({ delay: ANIM_DELAY * (_index + 1) })}
              >
                {text.title}
              </Text>
            )}
            {text.title && text.description && <div className={styles.itemMargin} />}
            {text.description && (
              <Text
                className={styles.itemDescription}
                variant="sm"
                {...fadeUp({ delay: ANIM_DELAY * (_index + 1) + ANIM_DELAY / 2 })}
              >
                {text.description}
              </Text>
            )}
          </div>
        ))}
      </div>
    </BasicSection>
  )
}

export default BasicSectionTexts
