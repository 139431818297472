/*
 * MQ
 */

import { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

export const MQ = {
  breakpoints: {
    mobileSM: 320,
    mobile: 425,
    tablet: 780,
    laptopSM: 1024,
    laptop: 1380,
    laptopLG: 1700,
    fullHD: 1920,
  },
}

export const useMediaMatches = ({
  mediaQuery,
  maxWidth,
  defaultMatches,
}: {
  mediaQuery?: string
  maxWidth?: number
  defaultMatches?: boolean
}): boolean => {
  const [_, forceUpdate] = useState<boolean>(false)

  useEffect(() => {
    forceUpdate(true) // Обновляем на клиенте
  }, [])

  return useMediaQuery(mediaQuery || (maxWidth ? `(max-width:${maxWidth - 1}px)` : ''), {
    defaultMatches,
  })
}
